export type Invitation = {
  id: string;
  minutes: number;
};

export const INVITATION_TYPE = {
  MINUTES: 'minutes',
  FAIRIES_ALPHA: 'fairies_alpha',
};

export type InvitationType =
  (typeof INVITATION_TYPE)[keyof typeof INVITATION_TYPE];

export const InvitationTypeMap: Record<InvitationType, string> = {
  fairies_alpha: 'Fairies Alpha',
  minutes: 'Minutes',
};

type InvitationMetadata = {
  type: InvitationType;
  minutes: number;
};

export type InvitationFormData = InvitationMetadata & {
  count: number;
};

export type InvitationsFormData = InvitationMetadata & {
  emails: string[];
};

export type InvitationData = {
  created_at: string;
  creator_id: string;
  id: string;
  minutes: number;
  metadata?: InvitationMetadata;
  redeem_time: string | null;
  user_id: string | null;
};
