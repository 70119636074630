import "./styles/base.scss";

import { GoogleOAuthProvider } from "@react-oauth/google";
import mixpanel from "mixpanel-browser";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA4 from "react-ga4";

import App from "./App";
import { USE_STRICT } from "./constants/app";
import reportWebVitals from "./reportWebVitals";

ReactGA4.initialize("G-1PVKVMN76F");
mixpanel.init("2997335a40d4b8b38f36f0091d0a397a", {
  track_pageview: true,
  persistence: "localStorage",
});
const root = ReactDOM.createRoot(document.getElementById("root"));

let app;

if (USE_STRICT) {
  app = (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  app = <App />;
}
const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ||
  "281910024740-jc555vdt115mgnuuk8ura80hmc1gokfo.apps.googleusercontent.com";

root.render(
  <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
    {app}
  </GoogleOAuthProvider>,
);

function sendToAnalytics({ id, name, value }) {
  ReactGA4.event({
    action: name,
    category: "Web Vitals",
    label: id,
    nonInteraction: true,
    value: Math.round(name === "CLS" ? value * 1000 : value),
  });
}
reportWebVitals(sendToAnalytics);
