import { useCallback } from 'react';
import { Redirect } from 'react-router-dom';

import { APP_ROUTE } from 'src/constants/routes';

export const useRedirectTo = (route: string = APP_ROUTE) => {
  const RedirectToPage = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (from?: string, params: any = undefined) => {
      let dynamicRoute = route;
      for (const key in params) {
        dynamicRoute = dynamicRoute.replace(`:${key}`, params[key]);
      }

      const to = from
        ? { pathname: dynamicRoute, state: { from } }
        : dynamicRoute;
      return <Redirect to={to} />;
    },
    [route],
  );

  return {
    RedirectToPage,
  };
};
