import { UserTier } from 'common-ui';
import { FC, lazy, ReactNode, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Loader from 'src/components/Loader';

import {
  ADMIN_CHATS_ROUTE,
  ADMIN_COMPLETED_GAME_PLAYS_ROUTE,
  ADMIN_GAME_INSTANCE_ROUTE,
  ADMIN_INVITATION_ROUTE,
  ADMIN_RUNNING_GAME_PLAYS_ROUTE,
  ADMIN_USER_ROUTE,
  ADMIN_USERS_ROUTE,
  AGENT_CREATE_ROUTE,
  AGENT_EDIT_ROUTE,
  AGENT_VIEW_ROUTE,
  AGENTS_ROUTE,
  APP_ROUTE,
  GOOGLE_LOGIN_ROUTE,
  PAYMENT_CANCELED_ROUTE,
  PRICE_ROUTE,
  SETTINGS_ROUTE,
} from 'src/constants/routes';

import AppRoute from './Route/AppRoute';
import PrivateRoute from './Route/PrivateRoute';

const GameInstanceDetailPage = lazy(
  () => import('src/pages/admin/GameInstance'),
);
const AdminUserPage = lazy(() => import('src/pages/admin/User'));
const AgentsPage = lazy(() => import('src/pages/private/AgentsPage'));
const ChatsPage = lazy(() => import('src/pages/private/ChatsPage'));
const CompletedGamePlaysPage = lazy(
  () => import('src/pages/private/CompletedGamePlaysPage'),
);
const CreateAgentPage = lazy(() => import('src/pages/private/CreateAgent'));
const EditAgentPage = lazy(() => import('src/pages/private/EditAgent'));
const InvitationCodesPage = lazy(
  () => import('src/pages/private/InvintationCodesPage'),
);
const PaymentCanceledPage = lazy(
  () => import('src/pages/private/PaymentCanceled'),
);
const PricePage = lazy(() => import('src/pages/private/Price'));
const RunningGamePlaysPage = lazy(
  () => import('src/pages/private/RunningGamePlaysPage'),
);
const SettingsPage = lazy(() => import('src/pages/private/SettingsPage'));
const UsersPage = lazy(() => import('src/pages/private/Users'));
const ViewAgentPage = lazy(() => import('src/pages/private/ViewAgent'));
const GoogleLoginPage = lazy(() => import('src/pages/public/GoogleLogin'));
const LandingPage = lazy(() => import('src/pages/public/LandingPage'));

type AppRoutesProps = {
  isValid: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  accessTier?: UserTier;
};

const AppRoutes: FC<AppRoutesProps> = ({
  isValid,
  isLoading,
  isLoaded,
  accessTier,
}) => {
  const createPrivateRoute = (
    path: string,
    requiredAccessTier: UserTier,
    component: ReactNode,
    redirectPath?: string,
  ) => (
    <PrivateRoute
      path={path}
      requiredAccessTier={requiredAccessTier}
      {...{ isValid, isLoading, isLoaded, accessTier }}
      redirectPath={redirectPath}
      component={component}
    />
  );

  return (
    <Suspense fallback={<Loader fullPage />}>
      <Switch>
        {/* PUBLIC PAGES */}
        <AppRoute
          exact
          path={GOOGLE_LOGIN_ROUTE}
          component={<GoogleLoginPage />}
        />
        <AppRoute exact path={APP_ROUTE} component={<LandingPage />} />
        {/* USER PRIVATE PAGES */}
        {createPrivateRoute(SETTINGS_ROUTE, UserTier.REGULAR, <SettingsPage />)}
        {createPrivateRoute(AGENTS_ROUTE, UserTier.REGULAR, <AgentsPage />)}

        {/* ADMIN PAGES */}
        {/* Need to split the admin pages into different tiers */}
        {createPrivateRoute(
          ADMIN_RUNNING_GAME_PLAYS_ROUTE,
          UserTier.ADMIN,
          <RunningGamePlaysPage />,
        )}
        {createPrivateRoute(
          ADMIN_COMPLETED_GAME_PLAYS_ROUTE,
          UserTier.ADMIN,
          <CompletedGamePlaysPage />,
        )}
        {createPrivateRoute(
          ADMIN_INVITATION_ROUTE,
          UserTier.ADMIN,
          <InvitationCodesPage />,
        )}
        {createPrivateRoute(ADMIN_USERS_ROUTE, UserTier.ADMIN, <UsersPage />)}

        {createPrivateRoute(
          ADMIN_USER_ROUTE + '/:userId',
          UserTier.ADMIN,
          <AdminUserPage />,
        )}
        {/* GAME PAGES */}
        {createPrivateRoute(
          ADMIN_GAME_INSTANCE_ROUTE + '/:instanceId',
          UserTier.ADMIN,
          <GameInstanceDetailPage />,
        )}
        {/* AGENT PAGES */}
        {createPrivateRoute(
          AGENT_CREATE_ROUTE,
          UserTier.GAME_EDITOR,
          <CreateAgentPage />,
          AGENTS_ROUTE,
        )}
        {createPrivateRoute(
          AGENT_EDIT_ROUTE + '/:id',
          UserTier.GAME_EDITOR,
          <EditAgentPage />,
          AGENT_VIEW_ROUTE + '/:id',
        )}
        {createPrivateRoute(
          AGENT_VIEW_ROUTE + '/:id',
          UserTier.REGULAR,
          <ViewAgentPage />,
        )}

        {/* CHAT PAGES */}
        {createPrivateRoute(ADMIN_CHATS_ROUTE, UserTier.ADMIN, <ChatsPage />)}

        {/* PAYMENT PAGES */}
        {createPrivateRoute(
          PAYMENT_CANCELED_ROUTE,
          UserTier.REGULAR,
          <PaymentCanceledPage />,
        )}
        {createPrivateRoute(PRICE_ROUTE, UserTier.ADMIN, <PricePage />)}

        <AppRoute
          path='*'
          component={<Redirect to={ADMIN_RUNNING_GAME_PLAYS_ROUTE} />}
        />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
