import { useMemo } from 'react';

import { useApi } from 'src/services/api/hooks/useApi';

import { BaseUseApiProps } from 'src/types/api/api.types';

import { makeApiSubmitInvitation } from '../functions/makeApiManage';

export const useApiSubmitInvitation = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiSubmitInvitation(), []);

  return useApi({ doRequest, onSuccess, onResponse, onError });
};
import {
  makeApiCreateInvitation,
  makeApiDeleteInvitation,
} from '../functions/makeApiManage';

export const useApiCreateInvitation = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiCreateInvitation(), []);

  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};

export const useApiDeleteInvitation = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiDeleteInvitation(), []);

  return useApi({ doRequest, onSuccess, onResponse, onError });
};
