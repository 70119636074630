import { api } from 'common-ui';
import { URLS } from 'common-ui';

import { ManageInvitationRequestData } from 'src/types/api/invitation.types';
import { INVITATION_TYPE } from 'src/types/dao/invitation.types';

export const makeApiSubmitInvitation =
  () =>
  ({ code }: { code: string }) => {
    const params = { invitation_id: code };
    return api.get<void>(URLS.invitation.accept, { params });
  };

export const makeApiCreateInvitation =
  () =>
  ({ formData }: { formData: ManageInvitationRequestData }) => {
    if (formData.type === INVITATION_TYPE.MINUTES) {
      return api.post<void>(
        `${URLS.invitation.createInvitation}?count=${formData.count}`,
        {
          metadata: {
            type: formData.type,
            minutes: formData.minutes,
          },
        },
      );
    }

    return api.post<void>(
      `${URLS.invitation.createInvitation}?count=${formData.count}`,
      {
        metadata: { type: formData.type },
      },
    );
  };

export const makeApiDeleteInvitation =
  () =>
  ({ id }: { id: string }) =>
    api.delete<void>(URLS.invitation.deleteInvitation, {
      params: { invitation_id: id },
    });
